@import url('../fonts/stylesheet.css');

@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
